import oToast from '@/objects/o-toast/o-toast.vue'
import oLogo from '@/objects/o-logo/o-logo.vue'

export default {
    name: "LayoutPublic",

    components: {
        "o-toast": oToast,
        "o-logo": oLogo
    },

    data() {
      return {

      };
    }
};
