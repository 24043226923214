<template>
  <layout-public>
    <template slot="page-content">
      <router-view></router-view>
    </template>
  </layout-public>
</template>

<script>
import LayoutPublic from '@/layouts/public/layout-public.vue';

export default {
  name: 'session',
  components: {
    'layout-public': LayoutPublic
  }
};
</script>
